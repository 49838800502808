.container {
  font-family: "Trebuchet MS", sans-serif;
  text-align: center;
  background-color: #000;
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  align-items: center;
}

.logo {
  width: 200px;
  height: auto;
}

.logoContainer {
  align-items: center;
  justify-content: center;
  display: grid;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.queries {
  margin-bottom: 0;
}

.email {
  margin-top: 0;
}

footer {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.footer-text {
  font-size: 0.8rem;
}
